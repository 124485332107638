import React from "react";
import { Text, RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import Link from "../../../core/Components/Link";
import { DefaultProps, PropTypes } from "../../../core/Utils/SitecoreFields";
import ScrollAnimation from "react-animate-on-scroll";

class ProcessFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const {
      title,
      description,
      hasFade,
      secondaryLink,
      link,
      backgroundColorOption,
      processOption,
    } = this.props.fields;

    const contentBlock = (
      <div
        className={`ProcessFeature  ${
          backgroundColorOption ? backgroundColorOption.value : ""
        }`}
      >
        <div className="l-padding">
          <div className="process-feature-title">
            <Text field={title} tag="h2" />
            <RichText field={description} className="content" />
          </div>

          <div className="process-feature-cards">
            <div className="content-container__grouper">
              <div
                className={
                  processOption.length >= 3
                    ? "sl  is-33-split"
                    : "sl  is-55-split"
                }
              >
                <div
                  className={
                    processOption.length >= 3
                      ? "sl-list has-3-items"
                      : "sl-list has-" + processOption.length + "-items"
                  }
                >
                  {processOption.map((process, index) => {
                    return index <= 2 ? (
                      <div className="sl-item" key={process.id}>
                        <div className="card">
                          {!process.fields.icon.value.src ||
                          !process.fields.useIconStyle.value ? (
                            <>
                              <div className="icon-box">
                                <h2>{index + 1}</h2>
                              </div>
                              <h3>{process.fields.title.value}</h3>
                            </>
                          ) : (
                            <>
                              <div className="icon-box">
                                <Image
                                  field={process.fields.icon}
                                  className="icon-image"
                                />
                              </div>
                              <h3>
                                {index + 1}. {process.fields.title.value}
                              </h3>
                            </>
                          )}
                          <RichText
                            field={process.fields.description}
                            className="content"
                          />
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          </div>
          {link.value.href ? (
            <div className="cta-grouping">
              <Link
                field={link}
                className={
                  backgroundColorOption.value !== "Green"
                    ? "cta-button-ni-link"
                    : "cta-button-ni-link light"
                }
              />
              {secondaryLink.value.href ? (
                <Link
                  field={secondaryLink}
                  className={
                    backgroundColorOption.value !== "Green"
                      ? "cta-button-ni-link secondary"
                      : "cta-button-ni-link secondary light"
                  }
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
    return hasFade && hasFade.value ? (
      <ScrollAnimation
        animateIn="toFadeInUp"
        animateOnce
        delay={100}
        duration={0.5}
      >
        {contentBlock}
      </ScrollAnimation>
    ) : (
      contentBlock
    );
  }
}

ProcessFeature.propTypes = PropTypes.SitecoreItem.inject({
  ...DefaultProps.ContentBlock,
  title: PropTypes.SingleLineText,
  description: PropTypes.RichText,
  hasFade: PropTypes.Checkbox,
  link: PropTypes.link,
  secondaryLink: PropTypes.link,
  backgroundColorOption: PropTypes.Multilist,
  processOption: PropTypes.Multilist.inject({
    title: PropTypes.SingleLineText,
    description: PropTypes.RichText,
    useIconStyle: PropTypes.Checkbox,
    icon: PropTypes.Image,
  }),
});

ProcessFeature.defaultProps = DefaultProps.SitecoreItem.inject({
  ...DefaultProps.ContentBlock,
  title: DefaultProps.SingleLineText,
  description: DefaultProps.RichText,
  hasFade: DefaultProps.Checkbox,
  link: DefaultProps.link,
  secondaryLink: DefaultProps.link,
  backgroundColorOption: DefaultProps.Multilist,
  processOption: DefaultProps.Multilist,
});

export default ProcessFeature;
